body {
  font-family: 'Lato', sans-serif;
  background-color: #f4f8f8;
  color: #333;
  margin: 0;
  padding: 20px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

h1 {
  font-size: 25px;
  text-align: left;
  color: #113630;
  margin-bottom: 0px;
  font-weight: bold;
}

.section {
  margin-bottom: 30px;
}

.table-section-header {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: lighter;
  text-align: left;
  color: #113630;
  padding: 5px 4px; /* Match the <h2> padding */
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
}

.column-headers th {
  border: none;
  padding: 10px;
  text-align: left;
  color: #113630;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}

thead th {
  border-bottom: 1px solid #ddd;
  padding: 48px 0 4px 15px;
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
}

th, td {
  padding: 11px 15px;
  text-align: left;
  font-weight: normal;
  font-size: 15px;
}

td {
  background-color: #fff;
  color: #113630;
}

a {
  padding: 0;
  margin: 0;
}

.file-link {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.file-link:hover {
  color: #43ccb7;
}

.download-icon {
  width: 20px;
  height: 20px;
}

tr:nth-child(even) td {
  background-color: #f9f9f9;
}
